import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Result } from 'postcss';
import { Observable, of } from 'rxjs';
import { map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { UserDetailsService } from './core/services/user-details.service';
import { UserStateService } from './core/services/user-state.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public router: Router,
        private authService: MsalService,
        private userStateService: UserStateService,
        private userDetailsService: UserDetailsService,
        private spinner: NgxSpinnerService) { }

    canActivate(): Observable<boolean> {

        this.spinner.show();
        var accountInfo = this.authService.instance.getAllAccounts();

        if (accountInfo.length <= 0) {
            this.router.navigate(['/auth']);
        }

        return this.userDetailsService.isUserRegistered(accountInfo[0].localAccountId)
            .pipe(mergeMap((isUserRegistered: boolean) => {
                this.spinner.hide();
                if (isUserRegistered) {
                    if (!this.userStateService.userDetailId) {
                        return this.userStateService.populate(accountInfo[0].localAccountId).pipe(map(_ => true));
                    }
                    else {
                        return of(true);
                    }
                } else
                    return this.router.navigate(['onboarding']);
            }));
    }


}
