<mat-toolbar color="primary">
  <span>Please Enter Details</span>
</mat-toolbar>

<form [formGroup]="fgUserDetails" (ngSubmit)="onSubmit()">
  <mat-dialog-content>



    <div fxLayout="column" fxFlex fxLayoutAlign="space-around stretch" fxlayoutgap="20px" fxLayoutGap.lt-md="0px">
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px" fxLayoutAlign="start stretch"
        fxlayout.gt-xs="row wrap">

        <mat-form-field appearance="fill" fxFlex="0 1 auto">
          <mat-label>Title</mat-label>
          <mat-select formControlName="Title">
            <mat-option *ngFor="let title of  Titles" [value]="title">
              {{title}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="" formControlName="Name">

        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="" formControlName="Surname">

        </mat-form-field>
      </div>

      <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Email</mat-label>
          <input matInput placeholder="" formControlName="Email">

        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayout.lt-md="column" fxFlex fxLayoutAlign="space-around stretch" fxLayoutGap="16px">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field appearance="fill">
            <mat-label>Telephone</mat-label>
            <input type="tel" matInput placeholder="" formControlName="Phone">
          </mat-form-field>
        </div>
        <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">
          <mat-form-field appearance="fill" fxFlex="1 1 auto">
            <mat-label>Company name</mat-label>
            <input matInput placeholder="" formControlName="CompanyName">
          </mat-form-field>

        </div>

      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px" class="mat-body-2">
        By proceeding you agree to M&A Deal Platforms<a mat-button href="https://www.madealplatform.com/privacy-policy"
          target="_blank">Privacy Policy</a>and<a mat-button
          href="https://www.madealplatform.com/terms-conditions?hsLang=en" target="_blank">Terms of Service</a>
      </div>
      <mat-divider></mat-divider>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button type="submit" mat-raised-button [disabled]="!fgUserDetails.valid" dkFocusInitial>Save & Proceed</button>

  </mat-dialog-actions>


</form>