<form [formGroup]="contentGeneratorFg" (ngSubmit)="onSubmit()">


    <div fxLayout="column" fxLayoutAlign="center stretch">
       <h3>Create Content with Secure ChatGPT</h3>
   

        <mat-form-field class="example-full-width">
            <mat-label>AI Prompt Subject</mat-label>
            <textarea  formControlName="SubjectContent" matInput
            cdkTextareaAutosize
            #autosize3="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="25"></textarea>
            <mat-hint>Example Prompt Subject: top 10 business tips for a startup</mat-hint>
        </mat-form-field>
  
        <mat-form-field appearance="fill">
            <mat-label>Response Length</mat-label>
            <mat-select formControlName="Length"> 
              <mat-option value="less than 144 characters">less than 144 characters</mat-option>
              <mat-option value="less than 20 words ">less than 20 words</mat-option>
              <mat-option value="less than 50 words">less than 50 words </mat-option>
              <mat-option value="less than 100 words">less than 100 words </mat-option>
              <mat-option value="less than 250 words">less than 250 words </mat-option>
              <mat-option value="less than 500 words">less than 500 words </mat-option>
              <mat-option value="less than 1,000 words">less than 1,000 words </mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance="fill">
            <mat-label>Response Type</mat-label>
            <mat-select formControlName="MediumType">
              <mat-option value="Ad Copy">Ad Copy</mat-option>
              <mat-option value="Article Write">Article Write</mat-option>
              <mat-option value="Blog Post">Blog Post</mat-option>
              <mat-option value="Case Study">Case Study</mat-option>
              <mat-option value="Content Outlines">Content Outlines</mat-option>
              <mat-option value="Email Write">Email Write</mat-option>
              <mat-option value="FAQs Create">FAQs Create</mat-option>
              <mat-option value="Newsletter Content">Newsletter Content</mat-option>
              <mat-option value="Press Release">Press Release</mat-option>
              <mat-option value="Social Media Updates">Social Media Updates</mat-option>
              <mat-option value="Summary Text">Summary Text</mat-option>
              <mat-option value="Testimonials Write">Testimonials Write</mat-option>
              <mat-option value="Twitter Post">Twitter Post</mat-option>
              <mat-option value="Review Write">Review Write</mat-option>
              <mat-option value="Website Copy">Website Copy</mat-option>
              <mat-option value="Commentary Create">Commentary Create</mat-option>
              <mat-option value="Whitepaper Create">Whitepaper Create</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance="fill">
            <mat-label>Response Tone</mat-label>
            <mat-select formControlName="MessageTone">
              <mat-option value="Analytical Tone">Analytical Tone</mat-option>
              <mat-option value="Casual Tone">Casual Tone</mat-option>
              <mat-option value="Concise Tone">Concise Tone</mat-option>
              <mat-option value="Conversational Tone">Conversational Tone</mat-option>
              <mat-option value="Customer-focused Tone">Customer-focused Tone</mat-option>
              <mat-option value="Empathetic Tone">Empathetic Tone</mat-option>
              <mat-option value="Encouraging Tone">Encouraging Tone</mat-option>
              <mat-option value="Enthusiastic Tone">Enthusiastic Tone</mat-option>
              <mat-option value="Friendly Tone">Friendly Tone</mat-option>
              <mat-option value="Humorous Tone">Humorous Tone</mat-option>
              <mat-option value="Friendly Tone">Friendly Tone</mat-option>
              <mat-option value="Informative Tone">Informative Tone</mat-option>
              <mat-option value="Inspirational Tone">Inspirational Tone</mat-option>
              <mat-option value="Friendly Tone">Friendly Tone</mat-option>
              <mat-option value="Patient Tone">Patient Tone</mat-option>
              <mat-option value="Playful Tone">Playful Tone</mat-option>
              <mat-option value="Process-driven Tone">Process-driven Tone</mat-option>
              <mat-option value="Professional Tone">Professional Tone</mat-option>
              <mat-option value="Sincere Tone">Sincere Tone</mat-option>
              <mat-option value="Strategic Tone">Strategic Tone</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>AI Engine Type</mat-label>
            <mat-select formControlName="AIEngineType">
              <mat-option value="Rapid">Rapid</mat-option>
              <mat-option value="Standard">Standard</mat-option>
              <mat-option value="Complex">Complex</mat-option>
            </mat-select>
          </mat-form-field>

   
        <mat-form-field  appearance="fill">
            <mat-label>AI Prompt (what is used)</mat-label>
            <textarea  formControlName="QueryText" matInput
                      cdkTextareaAutosize
                      #autosize2="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="25"></textarea>
          </mat-form-field>
                       
      <button class="save-button" mat-flat-button  color="primary" (click)="generateContent()">
        Run AI Prompt and Generate Response
      </button>
      
        <mat-form-field class="result-texbox">
            <mat-label>AI Generated Content Result</mat-label>
            <div class="spinner-wrapper" *ngIf="ShowLoader">
              <div fxLayout="column" fxLayoutAlign="center center">
                <mat-spinner></mat-spinner>
                <span>Loading AI Response...</span>
              </div>
            </div>
            <textarea  formControlName="GeneratedText" matInput  cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="200" ></textarea>
         
            <mat-progress-spinner>
            </mat-progress-spinner>
          </mat-form-field>
        

          <button  matTooltip="Copy Response to Clipboard"  mat-flat-button  color="primary" (click)="copyContentToClipBoard()">
            Copy Response to Clipboard
          </button>
    </div>
</form>