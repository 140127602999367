import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { OnboardingModule } from './onboarding/onboarding.module';
import { MaterialModule } from './shared/material.module';
import { CompanyModule } from './company/company.module';
import { PlatformCoreModule } from './core/platform-core.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { b2cPolicies, apiConfig, msalConfig } from './b2c-config';
import {  TestComponent } from './test/test.component';
import { UserModule } from './user/user.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { SidenavModule } from './shared/sidenav.module';
 
import { AuthGuard } from './auth-guard.guard';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LandingPageComponent } from './company-details/landing-page/landing-page.component';
 
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconRegistry } from '@angular/material/icon';
import { LetsTakTogetherComponent } from './Utility/lets-tak-together/lets-tak-together.component';
import { LoginComponent } from './login/login.component';
//import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { SafeHtmlPipeDocument } from './core/Pipes/pipe-transform.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LearnHowToSellComponent } from './learn-how-to-sell/learn-how-to-sell.component';
import { AssessComponent } from './ThreeSixtyReports/three-sixty-reports/assess/assess.component';
import { GrowComponent } from './ThreeSixtyReports/three-sixty-reports/grow/grow.component';
import { OptimiseComponent } from './ThreeSixtyReports/three-sixty-reports/optimise/optimise.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { InsightsModule } from './core/insights/insights.module';
import { SecureChatGPTComponent } from './SecureChatGPT/secure-chat-gpt/secure-chat-gpt.component';
import { CorporateFinanceLeadGenModule } from "./corporate-finance-lead-gen/corporate-finance-lead-gen.module";
 
 
 


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: msalConfig.auth.clientId,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(apiConfig.uri, apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [... apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
    declarations: [
        AppComponent,
        TestComponent,
        WelcomeComponent,
        LandingPageComponent,
        LetsTakTogetherComponent,
        LoginComponent,
        LearnHowToSellComponent,
        AssessComponent,
        GrowComponent,
        OptimiseComponent,
        SecureChatGPTComponent,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: LOCALE_ID, useValue: "en-GB" },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        AuthGuard,
        InsightsModule
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MsalModule,
        PlatformCoreModule,
        CompanyModule,
        MaterialModule,
        OnboardingModule,
        UserModule,
        SidenavModule,
        RouterModule,
        NgxSpinnerModule,
        AngularSvgIconModule.forRoot(),
        CorporateFinanceLeadGenModule
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}