import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { forkJoin } from 'rxjs';
import { empty } from 'rxjs/internal/observable/empty';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { ICurrentUser, ISelectedCompany } from 'src/app/shared/ICurrentUser';
import { IUserDetails } from 'src/app/shared/IUserDetails';
import { UserTypes } from 'src/app/shared/UserSetting';
import { CompanyDetailsService } from './company-details';
import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root',
})
export class UserStateService {
  currentUser: ICurrentUser;

  public DocumentAgreementId : number;

  public AddedNewCompany = new Subject();

  constructor(
    private userDetailsService: UserDetailsService,
    private companyDetailsService: CompanyDetailsService
  ) {
    this.currentUser = {} as ICurrentUser;
  }

  populateUserDetailOnly(objectId: string): Observable<any> {


    return this.userDetailsService.getUserDetails(objectId).pipe(map((userDetails: IUserDetails) => {

      this.currentUser.ObjectId = objectId;
      this.currentUser.UserDetailId = userDetails.UserDetailId;
      this.currentUser.Name = userDetails.Name;
      this.currentUser.Surname = userDetails.Surname;
      this.currentUser.Email = userDetails.Email;
      this.currentUser.ValuationSubscription = userDetails.ValuationSubscription;
      this.currentUser.PrimaryCompanyId = userDetails.PrimaryCompanyId;
      this.currentUser.SelectedCompanyId = userDetails.SelectedCompanyId;
      this.currentUser.UserTypeId = userDetails.UserTypeId;
    }));
  }

  populate(objectId: string): Observable<any> {


    return this.userDetailsService.getUserDetails(objectId).pipe(mergeMap(userDetails => {
      if (userDetails) {
        this.currentUser.ObjectId = objectId;
        this.currentUser.UserDetailId = userDetails.UserDetailId;
        this.currentUser.Name = userDetails.Name;
        this.currentUser.Surname = userDetails.Surname;
        this.currentUser.Email = userDetails.Email;
        this.currentUser.ValuationSubscription = userDetails.ValuationSubscription;
        this.currentUser.PrimaryCompanyId = userDetails.PrimaryCompanyId;
        this.currentUser.SelectedCompanyId = userDetails.SelectedCompanyId;
        this.currentUser.UserTypeId = userDetails.UserTypeId;
      }
      return this.companyDetailsService.getSelectedCompanyByUserId(userDetails.UserDetailId);
    })).pipe(map(company => {
      if (company) {
        this.currentUser.SelectedCompany = {} as ISelectedCompany;
        this.currentUser.SelectedCompany.CompanyId = company.CompanyDetailsId;
        this.currentUser.SelectedCompany.Name = company.Name;
        this.currentUser.SelectedCompany.CompanyUserAccessId = company.CompanyUserAccessId;
      }
    })).pipe(mergeMap(_ => this.companyDetailsService.getFullCompanyDetails(this.currentUser.PrimaryCompanyId)))
    .pipe(map(companyDetails=> { 
         this.currentUser.PrimaryCompany = {} as ISelectedCompany;
        this.currentUser.PrimaryCompany.Name = companyDetails.Name;
    
    } ));
     
  }


  updateSelectedCompany(userId: number): Observable<boolean> {
    return this.companyDetailsService.getSelectedCompanyByUserId(userId).pipe(map(company => {
      this.currentUser.SelectedCompany = {} as ISelectedCompany;

      if (company) {
        this.currentUser.SelectedCompany.CompanyId =
          company.CompanyDetailsId;
        this.currentUser.SelectedCompany.Name = company.Name;
        this.currentUser.SelectedCompany.CompanyId = company.CompanyDetailsId;
        this.currentUser.SelectedCompany.CompanyUserAccessId = company.CompanyUserAccessId;
        this.AddedNewCompany.next(true);
      }
      return true;
    }
    ));
  }

  get isCompanyOnboarded(): boolean {
    return this.currentUser.UserDetailId &&
      this.currentUser.SelectedCompany.CompanyId
      ? true
      : false;
  }

  get PrimaryCompany(): ISelectedCompany
  {
    return this.currentUser.PrimaryCompany;
  }

  get isUserOnboarded(): boolean {
    return this.currentUser.UserDetailId ? true : false;
  }

  get objectId(): string {
    return this.currentUser.ObjectId;
  }

  get userDetailId(): number {
    return this.currentUser.UserDetailId;
  }

  get selectedCompanyId(): number {
    return this.currentUser.SelectedCompany.CompanyId;
  }

  get companyUserAccessId(): number {
    return this.currentUser.SelectedCompany.CompanyUserAccessId;
  }


  get companyName(): string {
    return this.currentUser.SelectedCompany.Name;
  }

  get userName(): string {
    return this.currentUser.Name;
  }

  get userEmail(): string {
    return this.currentUser.Email;
  }

  get userSurname(): string {
    return this.currentUser.Surname;
  }

  get primaryCompanyId() : number
  {
    return this.currentUser.PrimaryCompanyId;
  }

  get primaryCompanyName() : string
  {
    return this.currentUser.PrimaryCompany.Name;
  }

  get userTypeId() : UserTypes
  {
    return this.currentUser.UserTypeId as UserTypes;
  }

  // get selectedCompanyId() : number
  // {
  //   return this.currentUser.SelectedCompanyId;
  // }

  get valuationSubscription(): number 
  {
    return this.currentUser.ValuationSubscription;
  }

  set valuationSubscription(valuationSubscription: number) {
     this.currentUser.ValuationSubscription = valuationSubscription;
  }
 
}
