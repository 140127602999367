import { environment } from "src/environments/environment";

 
/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_New_SUSI",
        forgotPassword: "b2c_1_reset",
        editProfile: "b2c_1_edit_profile",
        B2C_1A_SIGNUP_INVITATION : "B2C_1A_SIGNUP_INVITATION",
        B2C_1_register: "B2C_1_register",
  
    },
    authorities: {
        signUpSignIn: {
            authority: "https://madealplatformlive.b2clogin.com/madealplatformlive.onmicrosoft.com/B2C_1_New_SUSI",
        },
        forgotPassword: {
            authority: "https://madealplatformlive.b2clogin.com/madealplatformlive.onmicrosoft.com/B2C_1_reset",
        },
        editProfile: {
            authority: "https://madealplatformlive.b2clogin.com/madealplatformlive.onmicrosoft.com/B2C_1_edit_profile"
        },
        B2C_1A_SIGNUP_INVITATION:
        {
            authority: "https://madealplatformlive.b2clogin.com/madealplatformlive.onmicrosoft.com/B2C_1A_SIGNUP_INVITATION"
        },
        B2C_1_register: { authority: "https://madealplatformlive.b2clogin.com/madealplatformlive.onmicrosoft.com/B2C_1_register" },
    },
    authorityDomain: "madealplatformlive.b2clogin.com"
};

/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: {scopes: string[]; uri: string} = {
    scopes: ['https://madealplatformlive.onmicrosoft.com/979c5abe-5606-412a-9a38-09d3e05f4173/read_data'],
    uri: environment.apiHost
};
export const msalConfig = {
    auth: {
        clientId: 'e35cf0e0-3749-4ba5-a518-13f33017239f',
    }
};

